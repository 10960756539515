
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"渗透测试" },
            { name:"代码审计" },
            { name:"安全培训" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});

<template>
    <div class="group-bg-box">
         <div class="newword-security-box w-100p lh-60 h-60">
            <div class="network-security-pc-titles w-33p lh-60 h-60" v-for="(item,index) in items" :key="index"  @click="itemsClick(index)">
                {{item.name}}
                <div class="network-security-pc-titles-active" v-if="index == itemsActive"></div>
            </div>
        </div>
        <div v-if="itemsActive == 0">
            <div class=" ">
                <div class="about-item-box w-100p">
                    <div class="about-item-group">渗透测试</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-100p m-t-20">
                    <div class="about-item-group-title">
                        渗透测试-技术服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            渗透测试（Penetration Testing）是由具备高技能和高素质的安全服务人员发起、并模拟常见黑客所使用的攻击手段对目标系统进行模拟入侵。渗透测试服务的目的在于充分挖掘和暴露系统的弱点，从而让管理人员了解其系统所面临的威胁。渗透测试工作往往作为风险评估的一个重要环节，为风险评估提供重要的原始参考数据。渗透测试（Penetration Testing）是由具备高技能和高素质的安全服务人员发起、并模拟常见黑客所使用的攻击手段对目标系统进行模拟入侵。渗透测试服务的目的在于充分挖掘和暴露系统的弱点，从而让管理人员了解其系统所面临的威胁。渗透测试工作往往作为风险评估的一个重要环节，为风险评估提供重要的原始参考数据。
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        渗透测试-技术服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5">
                        <div class="newword-security-characteristic-pc-box w-100p m-b-15">
                            <img src="@/assets/image/company/network-security/1-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-25">
                                <div class="align-center p-b-25 f-26">风险梳理漏洞挖掘</div>
                                <div class="f-14">找出应用中存在的安全漏洞。按应用检测是对传统安全弱点的串联并形成路径，最终通过路径式的利用而达到模拟入侵的效果。 发掘应用中影响业务正常运行、导致敏感信息泄露、造成信誉损失的漏洞。</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box w-100p m-b-15">
                            <img src="@/assets/image/company/network-security/1-2.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-25">
                                <div class="align-center p-b-25 f-26">漏洞修复方案支持</div>
                                <div class="f-14">渗透测试目的是防御，故发现漏洞后，修复是关键。安全专家针对漏洞产生的原因进行分析，提出修复建议，以防御恶意攻击者的攻击。</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box w-100p m-b-15">
                            <img src="@/assets/image/company/network-security/1-3.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-25">
                                <div class="align-center p-b-25 f-26">专业安全报告</div>
                                <div class="f-14">漏洞修复后，对修复方案和结果进行有效性评估，分析修复方案的有损打击和误打击风险，验证漏洞修复结果。 汇总漏洞修复方案评估结果，标注漏洞修复结果，更新并发送测试报告。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        渗透测试服务对象
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5 d-flex d-flex-around d-flex-wrap">
                        <div class="newword-security-characteristic-pc-box m-b-2p w-45p">
                            <img src="@/assets/image/company/network-security/2-1.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">安卓应用</div>
                                <div class="f-14">对客户端、组件、本地数据、敏感信息、业务等 64 个检测项目进行安全检测</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box m-b-2p w-45p">
                            <img src="@/assets/image/company/network-security/2-2.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">ios应用</div>
                                <div class="f-14">对客户端、策略、通信、敏感信息、业务等 33 个检测项目进行安全检测</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box m-b-20 w-45p">
                            <img src="@/assets/image/company/network-security/2-3.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">网页应用</div>
                                <div class="f-14">对注入、跨站、越权、CSRF、中间件、规避交易、信息泄漏、业务等 67 个检测项进行安全检测</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box m-b-2p w-45p">
                            <img src="@/assets/image/company/network-security/2-4.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">微信服务号</div>
                                <div class="f-14">对客户端、组件、本地数据、敏感信息、业务等 64 个检测项目进行安全检测</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box m-b-2p w-45p">
                            <img src="@/assets/image/company/network-security/2-5.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">微信小程序</div>
                                <div class="f-14">根据小程序的开发特性，在SQL注入、越权访问、文件上传、CSRF以及个人信息泄露等漏洞进行检测，防护衍生的重大危害</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box m-b-2p w-45p">
                            <img src="@/assets/image/company/network-security/2-6.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-22">工控安全测试</div>
                                <div class="f-14">提供针对工控系统的专业渗透测试和安全评估</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="itemsActive == 1">
            <div class=" ">
                <div class="about-item-box w-100p">
                    <div class="about-item-group">代码审计</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg2.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-100p m-t-20">
                    <div class="about-item-group-title">
                        代码审计-技术服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            源代码审计（Code Review）是由具备丰富编码经验并对安全编码原则及应用安全具有深刻理解的安全服务人员对系统的源代码和软件架构的安全性、可靠性进行全面的安全检查。
      源代码审计服务的目的在于充分挖掘当前代码中存在的安全缺陷以及规范性缺陷，从而让开发人员了解其开发的应用系统可能会面临的威胁，并指导开发人员正确修复程序缺陷。
新上线徐彤对网络运行环境比较差，代码审计可以充分挖掘代码中存在的安全隐患，避免系统刚上线就遇到重大攻击
                        </div>
                    </div>
                </div>
            </div>
             <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        技术服务内容
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5 d-flex d-flex-around d-flex-wrap">
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        代码审计优势
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="network-security-yx-box p-b-5p">
                        <div class="network-security-yx-header">代码审计优势</div>
                        <div class="align-center">
                            <div class="network-security-yx-title1 m-t-2p">[团队能力强]</div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">多个领域安全项目经验，及具备丰富的安全编码经验</div>
                        </div>
                        <img src="@/assets/image/company/network-security/ys.png" class="w-60p m-l-20p m-t-20 m-b-20">
                        <div class="d-flex d-flex-around p-b-50">
                            <div class="align-center w-50p">
                                <div class="network-security-yx-title1 m-t-2p">[检查项目专业]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p w-80p m-l-10p f-12">检查类别涉及27大类，77个检查项目，项目多样化</div>
                            </div>
                             <div class="align-center  w-50p">
                                <div class="network-security-yx-title1 m-t-2p">[交付周到]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p  w-80p m-l-10p f-12">完善的报告交付要求，全程项目管控，实时在线问答</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="itemsActive == 2">
            <div class=" ">
                <div class="about-item-box w-100p">
                    <div class="about-item-group">安全培训</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg2.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-100p m-t-20">
                    <div class="about-item-group-title">
                        安全培训-技术服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            专业的网络安全培训，提高政府、企事业单位、各社会团体人员的安全意识和技术团队的网络安全技术能力。
                        </div>
                    </div>
                </div>
            </div>
             <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        技术服务内容
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5 d-flex d-flex-around d-flex-wrap">
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>
                        <div class="newword-security-audit-pc-box w-45p m-b-25 p-b-10">
                            <img src="@/assets/image/company/network-security/3-1.png" class="w-40p m-l-30p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white">
                                <div class="align-center p-b-25 f-18">系统所用开源框架</div>
                                <div class="f-14">包括反序列化漏洞，远程代码执行漏洞，spring、struts2安全漏洞，PHP安全漏洞等</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        代码审计优势
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="network-security-yx-box p-b-5p">
                        <div class="network-security-yx-header">代码审计优势</div>
                        <div class="align-center">
                            <div class="network-security-yx-title1 m-t-2p">[团队能力强]</div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">多个领域安全项目经验，及具备丰富的安全编码经验</div>
                        </div>
                        <img src="@/assets/image/company/network-security/ys.png" class="w-60p m-l-20p m-t-20 m-b-20">
                        <div class="d-flex d-flex-around p-b-50">
                            <div class="align-center w-50p">
                                <div class="network-security-yx-title1 m-t-2p">[检查项目专业]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p w-80p m-l-10p f-12">检查类别涉及27大类，77个检查项目，项目多样化</div>
                            </div>
                             <div class="align-center  w-50p">
                                <div class="network-security-yx-title1 m-t-2p">[交付周到]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p  w-80p m-l-10p f-12">完善的报告交付要求，全程项目管控，实时在线问答</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100p h-180"></div>
    </div>
</template>
<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"渗透测试" },
            { name:"代码审计" },
            { name:"安全培训" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});
</script>
<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
